import React from "react";
import { connect } from "react-redux";

import { withT } from "services/translation/";

import AccountPage from "components/Layout/AccountPage";

import Loading from "components/Loading";

import { UpdatePasswordForm } from "components/User/Profile/UpdatePasswordForm";

import { Form, InputGroup, FormControl, Button } from "react-bootstrap";

import CONXTDLogo from "../Layout/CONXTDLogo";
import BackgroundAnimation from "components/Common/BackgroundAnimation";

import Alert from "components/Common/Alert";

import { getRouterParams } from "services/redux/selectors/app/";

import validateEmail from "./validateEmail";


import {
  sendUserResetPasswordEmailStart,
  requestUserResetPasswordStart,
  requestUserResetPasswordNotified
} from "services/redux/actions";

import "./ResetPassword.scss";

// https://app.conxtd.com/#/login?Token=e29922ad42eedc5f17fb691add23e6d2

class ResetPassword extends React.Component {
  state = {
    emailValid: true
  };
  submitResetPassword = params => {
    const { password, password_confirm } = params;
    const { token } = this.props.match.params;

    this.props.dispatch(
      requestUserResetPasswordStart({ password, password_confirm, token })
    );
  };
  submitResetPasswordEmail = e => {
    e.preventDefault();

    const email = this.email.value;

    const emailValid = validateEmail(email);

    this.setState({ emailValid });

    if (!emailValid) return;

    this.props.dispatch(
      sendUserResetPasswordEmailStart({
        email
      })
    );
  };
  displayError(message) {
    Alert({
      text: message,
      icon: "error"
      // onAfterClose: () => {}
    });
  }
  displaySuccess(message, redirect = null) {
    Alert({
      text: message,
      icon: "success",
      onAfterClose: () => {
        if (redirect) {
          this.props.history.push(redirect);
        }
      }
    });
  }
  render() {
    const { user, t } = this.props;

    const { token } = this.props.match.params;

    const { resetPassword } = user.actions;

    const { emailValid } = this.state;

    // Valid token means it was a reset request
    if (token) {
      if (resetPassword.error.status === 422) {
        this.displayError(t("token_invalid"));
        this.props.dispatch(requestUserResetPasswordNotified());
      }
      if (resetPassword.success) {
        this.displaySuccess(t("reset_successfully"), "/login");
        this.props.dispatch(requestUserResetPasswordNotified());
      }
    }
    // Reset password email request
    else {
      if (resetPassword.error.status === 422) {
        this.displayError(t("invalid_email"));
        this.props.dispatch(requestUserResetPasswordNotified());
      }
      if (resetPassword.success) {
        this.displaySuccess(t("email_sent", this.email.value), "/login");
        this.props.dispatch(requestUserResetPasswordNotified());
      }
    }

    const content = token ? (
      <div className="row">
        <div className="col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
          <h5>{t("reset_password")}</h5>
          <UpdatePasswordForm
            submitButtonText={t("submit_button_text")}
            showCurrentPasswordField={false}
            onSubmit={this.submitResetPassword}
            loading={resetPassword.loading}
          />
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <h5>{t("request_password_reset")}</h5>
              <p>{t("request_password_reset_explanation")}</p>
            </div>
          </div>
          <Form onSubmit={this.submitResetPasswordEmail}>
            <Form.Row>
              <div className="col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                <Form.Group controlId="formGroupEmail">
                  <Form.Label>{t("enter_email_address")}</Form.Label>

                  <InputGroup className="mb-3">
                    <FormControl
                      ref={r => (this.email = r)}
                      onChange={e => {
                        this.setState({ emailValid: true });
                      }}
                      isInvalid={!emailValid}
                      name="email"
                      disabled={resetPassword.loading === true}
                    />
                    <InputGroup.Append>
                      <Button
                        size="sm"
                        variant="primary"
                        type="submit"
                        className="float-right"
                        disabled={
                          resetPassword.loading === true || !emailValid
                        }>
                        {resetPassword.loading === true ? (
                          <Loading center />
                        ) : (
                          t("send_email")
                        )}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </div>
            </Form.Row>
          </Form>
        </div>
      </div>
    );
    return (
      <div>
        {/* <video poster="/login-background-image.png" playsInline={true} autoPlay={true} muted={true} loop={true} className="bgvid">
          <source src="/login-background-animation.mp4" type="video/mp4" />
        </video> */}
        <BackgroundAnimation />
        <AccountPage id="accept-invite">
          <div className="row align-items-center accept-invite-page px-0 px-sm-4">
            <div className="col-12">
              <div className="login-header">
                <div className="row">
                  <div className="col-12 text-center">
                    <CONXTDLogo
                      style={{
                        height: "96px",
                        marginTop: "-10px"
                      }}
                      dark
                    />
                  </div>
                </div>
                {content}
              </div>
            </div>
          </div>
        </AccountPage>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    router: { params: getRouterParams(state, props) },
    user: state.user
  };
};
export default withT(connect(mapStateToProps)(ResetPassword), "reset_password");
