/**
 * Use "%1$s" to order parameters:
 *
 * user_name_full: "%1$s %2$s"
 * Kirt Rainford
 *
 * user_name_full: "%2$s %1$s"
 * Rainford Kirt
 *
 */

const defaults = {
  en: {
    page: {
      render_error: {
        title: "Page Not Available",
        description: "This page is currently unavailable.",
        return_to_dashboard: "Return to Dashboard",
      },
    },
    common: {
      timeframe_select: {
        hours: "%f H",
        days: "%f D",
        weeks: "%f W",
      },
    },
    breadcrumbs: {
      enterprises: "Enterprises",
      companies: "Service Desk",
      company: "Company",
      reports: "Reports",
      scheduled: "Scheduled",
      exception: "Exception",
      outstanding: "Outstanding",
      // 09/21 - open close by area
      openclosebyarea: "Open Close By Area",
      openclosebyareafull: "Full", 
      openclosedareas: "Open With Closed Areas", 
      closedopenareas: "Closed With Open Areas", 
      overview: "Overview",
      pair_bundle: "Pair Bundle",
      // panic_exception: "Panic Exception",
      panic_exception: "Panic",
      fire: "Fire",
      panic: "Panic",
      open: "Open",
      close: "Close",
      overnight_activity: "Overnight Activity",
      people_count: "People Counting Activity",
      people_count_hourly: "People Counting Hourly",
      suspicious_activity: "Suspicious Activity",
      facewatch: "Facewatch",
      user: "User",
      settings: "Settings",
      profile: "Profile",
      group_management: "Group Management",
      user_management: "User Management",
      alarm_response: "Priority Events", //alarm-response
      enterprise_manager: "Enterprise Manager",
      enterprise_manager_view_group: "Enterprise Manager View Group",
      enterprise_manager_add_site_from_root: "Add Site From Root",
      outlocks: "Access Requests",
      custom_reports: "Event IQ",
    },
    nav: {
      search: {
        group_x: "Group: %s",
        site_x: "Site: %s",
        placeholder: "Search enterprise",
        search_x_for_y: "Search %s for %s",
      },
      user_dropdown: {
        profile: "Profile",
        settings: "Settings",
        logout: "Logout",
        dark_mode: "Dark Mode",
        inject_events: "Inject Events",
        developers: "Developers"
      },
      sidebar: {
        overview: "Overview",
        reports: "Reports",
        user_management: "User Management",
        group_management: "Group Management",
        setup: "Setup",
        notification_manager: "Notification Manager",
        alarm_response: "Priority Events", //alarm-response
        enterprise_manager: "Enterprise Manager",
        enterprise_manager_view_group: "Enterprise Manager View Group",
        enterprise_manager_add_site_from_root: "Add Site From Root",
        enterprises: "Enterprises",
        companies: "Service Desk",
        all: "All",
        app_store: "App Store",
        help: "Help",
        logout: "Logout",
        enterprise_dashboard: "Enterprise Dashboard",
        custom_reports: "Event IQ",
      },
    },
    user: {
      settings: {},
      profile: {
        edit_profile: "Edit Profile",
        user_details: {
          title: "User Details",
          first_name: "First Name",
          last_name: "Last Name",
          save_changes: "Save Changes",
          profile_updated_successfully: "Your profile was updated successfully",
        },
        update_password: {
          title: "Update Password",
          current: "Current Password",
          new_password: "New Password",
          confirm: "Confirm New Password",
          update_password_button: "Update Password",
          password_requirements: "Please ensure passwords contain:",
          one_capital_letter: "One capital letter",
          one_lower_case_letter: "One lower case letter",
          one_number: "One number",
          one_special_character: "One special character",
          at_least_x_characters: "At least %i characters",
          password_updated_successfully:
            "Your password was updated successfully",
          error_422:
            "Your password could not be updated. Please check you have entered your current password correctly.",
        },
        // user_name_full: "%1$s %2$s",
      },
    },
    enterprises: {
      text_filter: {
        placeholder: "Type to filter enterprises...",
      },
    },
    companies: {
      text_filter: {
        placeholder: "Type to filter companies...",
      },
    },
    groups: {
      text_filter: {
        placeholder: "Type to filter groups...",
      },
    },
    timeline: {
      load_more: "Load More",
    },
    site_overview: {
      no_events_in_timeframe:
        "No events were recorded in the selected timeframe",
      side_pane: {
        manage_notifications: {
          tooltip_description: "Manage Notifications",
          back_to_overview: "Back to overview",
          title: "Notification Rules",

          load_error: "Couldn't load rules for this site",
          load_error_dev: "Couldn't load rules for this site [Dev: %s]",

          add_rule: {
            title: "Add Rule",
            save: "Save Rule",
            cancel: "Cancel",
            select_resource: {
              select_resource_labels: {
                email: "Select or enter a new email address",
                slack: "Select a Slack channel",
                hue: "Select a Hue device",
                push: "Select a push notification device",
              },
              add_new_email_address: "Add new email address: ",
              new_email_will_be_added: "This email address will be added",
              no_resources_for_channel:
                "No devices are set up for the selected notification type.",
              error: "Couldn't load resources",
              error_dev: "Couldn't load resources [Dev: %s]",
            },
            select_bundle: {
              all_events: "All Events",
              conxtd_reminders: "CONXTD Reminders",
              confirmed_events: "Confirmed Events",
              communication_events: "Communication Events",
              notifications_title: "Notifications",
              customise: "Customise",
              suggestions: "Suggestions",
              faults: "Faults",
              engineer: "Engineer",
              open_close: "Open/Close",
              suggestions_title: "Suggestions",
              custom_bundle: {
                bundle_label: "Event Bundle",
                select_bundle: "Select bundle...",
                class_label: "Alarm Class",
                select_class: "All",
              },
            },
            add_rule_success: "Notification rule added successfully",
            errors: {
              add_rule:
                "This notification rule could not be saved. Please try again later.",
              add_rule_dev:
                "This notification rule could not be saved. Please try again later. [Dev: %s]",
            },
          },
          remove_rule: {
            confirm_message:
              "Are you sure you wish to remove the selected rule?",
            remove_rule_success: "The rule was deleted successfully",
            confirm_button: "Remove Rule",
            cancel_button: "Cancel",
            errors: {
              remove_rule:
                "This notification rule could not be removed. Please try again later.",
              remove_rule_dev:
                "This notification rule could not be removed. Please try again later. [Dev: %s]",
            },
          },

          table: {
            group_level_rule_explanation:
              /* "This rule is inherited from a parent group and may apply to other sites", */
              "This rule is created at the site level and is specific to this site",
            site_level_rule_explanation:
              "This rule is created at the site level and is specific to this site",
            headers: {
              channel_code: "Type",
              channel_resource_name: "Resource",
              alarm_class_name: "Alarm Class",
              bundle_name: "Bundle Name",
              actions: "Actions",
            },
            types: {
              email: "Email",
              hue: "Hue",
              slack: "Slack",
              push: "Push",
            },
          },
        },
        manage_integrations: {
          tooltip_description: "Manage Cameras",
          back_to_overview: "Back to Overview",
          providers: {
            select_location: {
              available_locations: "Available Locations",
              placeholder: "Select location...",
            },
            tether: {
              connect_to_tether: "Connect to Tether",
              connected: "Connected",
              connecting_tether: "Connecting Tether...",
              active_integrations: "Active Providers",
              available_integrations: "Available Providers",
              no_integrations_available: "No integrations are available",
              no_cameras_added: "No cameras have been added to this site yet.",
              add_camera: "Add Camera",
              cancel_add_camera: "Cancel",
              add_camera_to_site: "Add Camera to Site",
              add_location: "Add Location",
              add_location_to_site: "Add Location To Site",
              cancel_add_location: "Cancel",
              select_camera: {
                available_cameras: "Available Cameras",
                placeholder: "Select camera...",
                live_camera_view: "Live Camera View",
              },
              preset_tooltip_description:
                "Click to view this preset on the Tether platform",
              remove_camera_confirm:
                "Are you sure you wish to remove this camera from %s?",
              remove_camera_tooltip: "Unassign this camera from the site",
              show_cameras: "Show Cameras",
              hide_cameras: "Hide Cameras",
            },
            eagle_eye: {
              connect_to_eagle_eye: "Connect to Eagle Eye",
              connect: "Connect",
              cancel: "Cancel",
              eagle_eye_username: "Eagle Eye Username",
              eagle_eye_password: "Eagle Eye Password",
              connected: "Connected",
              connecting_eagle_eye: "Connecting Eagle Eye...",
              active_integrations: "Active Providers",
              available_integrations: "Available Providers",
              no_integrations_available: "No integrations are available",
              no_cameras_added: "No cameras have been added to this site yet.",
              add_camera: "Add Camera",
              cancel_add_camera: "Cancel",
              add_camera_to_site: "Add Camera to Site",
              add_location: "Add Location",
              add_location_to_site: "Add Location To Site",
              cancel_add_location: "Cancel",
              load_video: "Load Video",
              select_camera: {
                available_cameras: "Available Cameras",
                placeholder: "Select camera...",
                live_camera_view: "Live Camera View",
              },
              select_location: {
                available_locations: "Available Locations",
                placeholder: "Select location...",
              },
              preset_tooltip_description:
                "Click to view this preset on the Eagle Eye platform",
              remove_camera_confirm:
                "Are you sure you wish to remove this camera from %s?",
              remove_camera_tooltip: "Unassign this camera from the site",
              show_cameras: "Show Cameras",
              hide_cameras: "Hide Cameras",
              credentials: {
                eagle_eye_username: "Eagle Eye Username",
                eagle_eye_password: "Eagle Eye Password",
              },
            },
            video_loft: {
              connect_to_video_loft: "Connect to Videoloft",
              connect_to_chubbview: "Connect to ChubbView",
              connect: "Connect",
              cancel: "Cancel",
              video_loft_username: "Videoloft Username",
              video_loft_password: "Videoloft Password",
              chubbview_username: "ChubbView Username",
              chubbview_password: "ChubbView Password",
              connected: "Connected",
              connecting_video_loft: "Connecting Videoloft...",
              connecting_chubbview: "Connecting ChubbView...",
              active_integrations: "Active Providers",
              available_integrations: "Available Providers",
              no_integrations_available: "No integrations are available",
              no_cameras_added: "No cameras have been added to this site yet.",
              add_camera: "Add Camera",
              cancel_add_camera: "Cancel",
              add_camera_to_site: "Add Camera to Site",
              add_location: "Add Location",
              add_location_to_site: "Add Location To Site",
              cancel_add_location: "Cancel",
              load_video: "Load Video",
              select_camera: {
                available_cameras: "Available Cameras",
                placeholder: "Select camera...",
                live_camera_view: "Live Camera View",
              },
              select_location: {
                available_locations: "Available Locations",
                placeholder: "Select location...",
              },
              preset_tooltip_description:
                "Click to view this preset on the Videoloft platform",
              remove_camera_confirm:
                "Are you sure you wish to remove this camera from %s?",
              remove_camera_tooltip: "Unassign this camera from the site",
              show_cameras: "Show Cameras",
              hide_cameras: "Hide Cameras",
              credentials: {
                video_loft_username: "Videoloft Username",
                video_loft_password: "Videoloft Password",
                chubbview_username: "ChubbView Username",
                chubbview_password: "ChubbView Password",
              },
            },
            brivo: {
              connecting_brivo: "Connecting Brivo...",
              connecting_brivo_failed: "Failed to add connection",
            }
          },
          select_bundle: {
            label: "Capture video on these events:",
            no_events_captured: "No events captured",
            save_settings: "Save Settings",
          },
        },
        mode_view: {
          // tooltip_description: "Event Filters",
          tooltip_description: "Engineers Mode Filter",
          back_to_overview: "Back to overview",
          engineers_mode: "Engineers Mode",
          test_mode: "Test Mode",
          timeline_button_tooltip: "View as Timeline",
          table_button_tooltip: "View as Table",
          data_table: {
            headers: {
              date: "Date",
              time: "Time",
              event: "Event",
              detail: "Detail",
              zone: "Zone",
              area: "Area",
              loop: "Loop",
              panel: "Panel",
              point: "Point",
            },
          },
          tabs: {
            engineers: "Engineers Mode Sessions",
            test: "Test"
          },
        },
        voice_calls: {
          tooltip_description: "Make a Call",
          back_to_overview: "Back to overview",
          no_keyholders: "This site has no contacts",
          no_call_logs: "This site has no call logs",
          tabs: {
            make_a_call: "Make a Call",
            call_logs: "Call Logs"
          }
        },
        work_order: {
          tooltip_description: "Work Order",
        },
        alarm_manager: {
          tooltip_description: "Alarm Manager",
        },

        tabs: {
          bundles: "Bundles",
          apps: "Apps",
          details: "Details",
          keyholders: "Contacts",
          comments: "Comments",
          cameras: "Cameras",
        },

        events: {
          area_x: "Area: %s",
          zone_x: "Zone: %s",
          loop_x: "Loop: %s",
          panel_x: "Panel: %s",
          point_x: "Point: %s",
          timeline_button_tooltip: "View as Timeline",
          today_at: "Today at ",
          yesterday_at: "Yesterday at ",
          table_button_tooltip: "View as Table",
          timeline_zone: "Zone: ",
          timeline_area: "Area: ",
          data_table: {
            headers: {
              date: "Date",
              time: "Time",
              event: "Event",
              detail: "Detail",
              zone: "Zone",
              area: "Area",
              loop: "Loop",
              panel: "Panel",
              point: "Point",
            },
          },
        },

        back_to_overview: "Back to overview",

        keyholders: {
          no_keyholders: "This site has no contacts",

          view_as: {
            cards: "View as Cards",
            table: "View as Table",
          },
          data_table: {
            headers: {
              position: "#",
              name: "Name",
              job_title: "Job Title",
              email: "Email",
              phone: "Phone",
              actions: "Actions",
            },
          },
          add: {
            title: "Add Contact",
            name: "Name",
            email: "Email",
            phone: "Phone",
            job_title: "Job Title",
            button_submit: "Add Contact",
            button_cancel: "Cancel",
            success_message: "Contact added successfully",
          },
          edit: {
            title: "Edit Contacts",
            button_done: "Done",
            phone: "Phone:",
            email: "Email:",
            job_title: "Title:",
            edit_button: "Edit",
            remove_button: "Remove",
            confirm_remove:
              "Are you sure you wish to remove the contact %s from the site? This action cannot be undone.",
            cancel_button: "Cancel",
            save_button: "Save",
            remove_success_message: "Successfully removed contact",
          },
        },
        event_pairs: {
          back_to_overview: "Back to overview",
        },
      },
      alarm_bundles: "Alarm Bundles",
      latest_event: "Latest",
      not_available: "No events in timeframe",
      unknown_site_status:
        "24 hour / fire site (Full Open / Close not been detected)",
      padlock_site_status:
        "Shows overall 'Full Open/Close' status of site (using area 1 where available)",
      no_alarms_in_timeframe:
        "No alarms were recorded for this site in the selected timeframe",
    },
    site_list: {
      data_table: {
        headers: {
          site_name: "Site Name",
          filter_x_sites: "Filter %i sites...",
          store_id: "Store ID",
          site_status: "Site Status",
          latest_alarm: "Latest Alarm",
          alarm_count: "Alarm Count",
          display_only_x_sites: "Display only %s sites",
          select_site_status_filter: "Select site status filter",
        },
        no_data_available: "No data available",
        no_alarms_in_timeframe:
          "No alarms were recorded for this site in the selected timeframe",
        unknown_site_status:
          "24 hour / fire site (Full Open / Close not been detected)",
        padlock_site_status:
          "Shows overall 'Full Open/Close' status of site (using area 1 where available)",
      },
    },
    group_overview: {
      multi_progress_bar: {
        tooltip: {
          title: "%i%% %s (%i/%i)",
        },
      },
      no_groups_found: "No groups found",
      showing_x_of_y_groups: "Showing %i of %i groups ",
      filter: {
        nonZero: {
          stats: {
            alarmColours: "With latest alarm: %s",
            openClosed: "In open state: %s",
            countHighAlarms: "With high activity",
          },
        },
        contains: {
          text: "Containing text: %s",
        },
      },
      no_info_available: "No group information available",
    },
    user_management: {
      header_title: "User Management",
      select_group_title: "Select Group",
      no_groups_available:
        "No groups available. You may not have access privileges to manage users.",
      user_list: {
        title: "Current Users",
        user_name_full: "%1$s %2$s",
        headers: {
          name: "Name",
          status: "Status",
          last_login: "Last Login",
          email: "Email",
          access: "Access",
          features: "Features",
          options: "Options",
        },
        no_data_available: "No users found",
        remove_user: {
          confirm_message:
            "Are you sure you want to remove access to %s for %s?",
          confirm_button: "Remove Access",
          cancel_button: "Back",

          success_message: "Successfully removed access to %s for %s",

          errors: {
            remove: "Unable to remove access.",
            remove_dev: "Unable to remove access. [Dev: %s]",
          },
        },
        edit_user: {
          full_name: "%1$s %2$s",
          manage_access_title: "Manage User",
          access_level: "Access Level",
          features: "Features",
          cancel_button: "Cancel",
          confirm_button: "Update User",
          success_message: "User updated successfully",
        },
        active: "Active",
        inactive: "Invite sent",
        last_login_x: "Last login: %s",
      },
      common: {
        role_select: {
          select_role: "Select role...",
          select_role_validation_feedback: "Please select an access level",
        },
      },
      user_invite: {
        title: "Invite User",

        email_address_input_label: "Email Address",
        name_input_label: "Name",
        first_name_input_label: "First Name (required)",
        first_name_validation_feedback: "Please enter a first name",
        last_name_validation_feedback: "Please enter a last name",
        last_name_input_label: "Last Name (required)",
        role_input_label: "Role (required)",
        features_input_label: "Features",
        company_input_label: "Company",
        job_title_input_label: "Job Title",

        search_description:
          "Enter the email address of the user you would like to add below and we will check if they are already a CONXTD user.",

        x_is_not_on_conxtd: "%s is not on CONXTD.",
        x_is_on_conxtd: "%s is on CONXTD.",

        full_name: "%1$s %2$s",

        invite_new_user_description:
          "Add any details below and then click Invite User to send an invite to them onto the CONXTD platform with access to %s.",
        invite_existing_user_description:
          "Select a role and features and click Grant Access to invite them to %s.",

        button_grant_access: "Grant Access",
        button_invite_user: "Invite User & Grant Access",
        button_search_user: "Search",
        cancel_button: "Cancel",
        select_domain: "Select domain...",
        user_already_invited:
          "%1$s already has access to this group. To manage %1$s, see the Current Users section.",
        errors: {
          invite: "Unable to invite user.",
          invite_dev: "Unable to invite user. [Dev: %s]",
          create: "Unable to create user.",
          create_dev: "Unable to create user. [Dev: %s]",
        },
        invite_success: "%1$s %2$s has been granted access to %3$s.",
        create_and_invite_success:
          "An email has been sent to %1$s with instructions on how to set up their account. Their account has also been granted access to %2$s.",
      },
    },
    group_management: {
      header_title: "Group Management",
    },
    enterprise_manager: {
      header_title: "Enterprise Manager",
    },
    alarm_response: { //alarm-response
      header_title: "Priority Events",
    },
    view_group: {
      header_title: "View Group - view/edit sites",
    },
    user_accept_invite: {
      submit_button_text: "Create Account",
      email_address: "Email Address",
      welcome_message: {
        hi_first_name: "Hi %s,",
        invited: "You've been invited to CONXTD.",
        invited_by_inviter: "You've been invited to CONXTD by %s.",
        check_details_below:
          "Check the details below and create your password.",
      },
      error_message: {
        invitation_already_accepted:
          "This invitation has already been accepted.",
        please_login: "Please login to view enterprises.",
      },
    },
    reset_password: {
      submit_button_text: "Reset Password",
      reset_password: "Reset Password",
      request_password_reset: "Request Password Reset",
      request_password_reset_explanation:
        "To reset your password, enter your email address below and a reset password link will be sent to you.",
      enter_email_address: "Enter Email Address",
      send_email: "Reset Password",
      email_sent:
        "An email has been sent to %s with instructions on how to reset your password.",
      invalid_email:
        "The email address you have entered was not found on our system. Please check the address and try again.",
      token_invalid:
        "Your password could not be reset: the authentication token is invalid or has expired.",
      reset_successfully:
        "Your password was reset successfully. You will now be redirected to the login page, where you can log in with your new password.",
    },
    login_form: {
      remember_me: "Remember me",
      log_in: "Log In",
      lost_password: "Lost Password",
      email_address_placeholder: "Email address",
      password_placeholder: "Password",
      request_invite: "Request an invite",
      errors: {
        incorrect_credentials:
          "The username or password you have entered is incorrect.",
        login_failed_x: "Login failed: %s",
      },
    },
    reports: {
      outstanding: {
        index: {
          title: "Outstanding Events",
          description: "Events still in alarm or fault",
        },
      },
      // 09/21 - open close by area
      openclosebyarea: { 
        index: {
          title: "Open Close By Area",
          description: "Site and Area Open/Close State",
        },
      },
      scheduled: {
        data_table: {
          abnormal_event_tooltip: {
            title: "Abnormal Event Detected",
            description:
              "This event has been flagged as abnormal because it occurred at a time when it wasn't expected to happen. This might happen when a site opens on a Sunday, when usually it would never open on Sundays.",
          },
          using_system_default_tooltip: {
            title: "Default Expected Time",
            description:
              "This site has no expected time set yet, so the system will assume the default expected time, for example 09.00 for open and 17.00 for close.",
          },
        },
      },
    },
  },
};

export default defaults;