import _ from "lodash";

const initialState = {
  // selectedTab: "Groups",
  items: [],
  loading: null,
  loadingViewGroup: null,
  loadingGroupDropdown: null,
  dropDownGroupsList: [],
  counts: [],
  loadingSiteDetail: null,
  siteDetail: [],
  groupStructure: [],
  loadingGroupStructure: null,
  dropDownGroupsListTree: null,
  userActions: [],
  loadingUserActions: null,
  integrationTabContent: "integrations",
  integrations: [],
  loadingIntegrations: null,
  loadingPinMaps: null,
  connectIntegration: { // written to be generic, but if need be in the future it can be split out into separate integrations e.g. connectBrivo etc
    status: null,
    error: {},
  },
  brivo: {
    integrationId: null,
    sites: [],
    linkedSites: [],
  },
  uptick: {
    integrationId: null,
    sites: [],
    linkedSites: [],
    pagination: {},
    loading: false,
  },
  priorityEventsManagement: {
    tabContent: "pe-management",
    eventPairList: [],
    eventPairMaps: {
      default_event_pair_maps: [],
      enterprise_event_pair_maps: [],
    },
    currentEventPairMap: {
      type: "default_event_pair_maps",
      name: "",
      eventPairId: null,
      eventPairMapId: null,
    },
    stepTypeList: [],
  },
  pinMaps: {},
};

/* Gets a list of all the enterprise site ids in the requested group
 */
export default function enterpriseManager(state = initialState, action) {
  switch (action.type) {
    // case "SET_EM_SELECTED_TAB":
    //   return {
    //     ...state,
    //     selectedTab: action.selectedTab,
    //   };

    case "SET_EM_INTEGRATION_TAB_CONTENT":
      return {
        ...state,
        integrationTabContent: action.integrationTabContent,
      }

    case "SET_EM_PRIORITY_EVENTS_MANAGEMENT_TAB_CONTENT":
      return {
        ...state,
        priorityEventsManagement: {
          ...state.priorityEventsManagement,
          tabContent: action.tabContent,
        }
      }

    case "LOAD_EM_SITES_LIST_START":
      return {
        ...state,
        loadingViewGroup: true,
        // items: action.payload.items,
      };

    case "LOAD_EM_SITES_LIST_SUCCESS":
      return {
        ...state,
        items: action.data,
        loadingViewGroup: false,
        data: {
          ...state.data,
        },
      };

    case "LOAD_EM_SITES_LIST_RESTORE":
      if (_.get(action, "unchanged")) {
        return { ...state, loadingViewGroup: false };
      }
      return { ...state, loadingViewGroup: false, data: action.data };

    case "LOAD_EM_SITES_LIST_FAILED":
      return {
        ...state,
        loadingViewGroup: false,
      };

    /* Gets a list of all groups for a enterprise
     */
    case "GET_GROUPS_DROPDOWN_LIST_START":
      return {
        ...state,
        loadingGroupDropdown: true,
      };

    case "GET_GROUPS_DROPDOWN_LIST_SUCCESS":
      return {
        ...state,
        dropDownGroupsList: action.data.enterpriseGroups,
        dropDownGroupsListTree: action.data.groupTree,
        loadingGroupDropdown: false,
      };

    case "GET_GROUPS_DROPDOWN_LIST_RESTORE":
      if (_.get(action, "unchanged")) {
        return { ...state, loadingGroupDropdown: false };
      }
      return { ...state, loadingGroupDropdown: false, data: action.data };

    case "GET_GROUPS_DROPDOWN_LIST__FAILED":
      return {
        ...state,
        loadingGroupDropdown: false,
      };

    /* Parsed a enterprise group id, responds with a site and report count */
    case "GET_COUNT_FOR_MAIN_PAGE_START":
      return {
        ...state,
        loading: true,
      };

    case "GET_COUNT_FOR_MAIN_PAGE_SUCCESS":
      return {
        ...state,
        counts: action.data,
        loading: false,
      };
    case "GET_COUNT_FOR_MAIN_PAGE_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "GET_COUNT_FOR_MAIN_PAGE_RESTORE":
      if (_.get(action, "unchanged")) {
        return { ...state, loading: false };
      }
      return { ...state, loading: false, data: action.data };
    /* Return details of a site */
    case "GET_SITE_DETAIL_START":
      return {
        ...state,
        loadingSiteDetail: true,
      };

    case "GET_SITE_DETAIL_SUCCESS":
      return {
        ...state,
        siteDetail: action.data,
        loadingSiteDetail: false,
      };
    case "GET_SITE_DETAIL_FAILED":
      return {
        ...state,
        loadingSiteDetail: false,
      };
    /* Get group structure*/
    case "GET_GROUP_STRUCTURE_START":
      return {
        ...state,
        loadingGroupStructure: true,
      };

    case "GET_GROUP_STRUCTURE_SUCCESS":
      return {
        ...state,
        groupStructure: renameKey(action.data.groups, {
          id: "key",
          name: "label",
          children: "nodes",
        }),
        loadingGroupStructure: false,
      };
    case "GET_GROUP_STRUCTURE_FAILED":
      return {
        ...state,
        loadingGroupStructure: false,
      };

    /* Get user actions */
    case "GET_USER_ACTIONS_START":
      return {
        ...state,
        loadingUserActions: true,
      };

    case "GET_USER_ACTIONS_SUCCESS":
      return {
        ...state,
        userActions: action.data.userActions,
        loadingUserActions: false,
      };
    case "GET_USER_ACTIONS_FAILED":
      return {
        ...state,
        loadingUserActions: false,
      };
    case "GET_USER_ACTIONS_CANCEL":
      return {
        ...state,
        loadingUserActions: false,
      };

      /* Get integrations */
    case "GET_INTEGRATIONS_START":
      return {
        ...state,
        loadingIntegrations: true,
      };

    case "GET_INTEGRATIONS_SUCCESS":
      const brivoIntegrationDetails = _.find(action.data.data, { "active": true, "name": "Brivo" });
      const uptickIntegrationDetails = _.find(action.data.data, { "active": true, "name": "Uptick" });
      return {
        ...state,
        integrations: action.data.data,
        brivo: {
          ...state.brivo,
          integrationId: brivoIntegrationDetails ? brivoIntegrationDetails.id : null,
        },
        uptick: {
          ...state.uptick,
          integrationId: uptickIntegrationDetails ? uptickIntegrationDetails.id : null,
        },
        loadingIntegrations: false,
      };
    case "GET_INTEGRATIONS_FAILED":
      return {
        ...state,
        loadingIntegrations: false,
      };
    case "GET_INTEGRATIONS_CANCEL":
      return {
        ...state,
        loadingIntegrations: false,
      };

    /* Integration Connect */  
    case "REQUEST_INTEGRATION_CONNECT_START":
      return {
        ...state,
        connectIntegration: {
          ...state.connectIntegration,
          status: "connecting",
        }
      };

    case "REQUEST_INTEGRATION_CONNECT_SUCCESS":
      return {
        ...state,
        connectIntegration: {
          ...state.connectIntegration,
          status: "connected",
        }
      };
    case "REQUEST_INTEGRATION_CONNECT_FAILED":
      return {
        ...state,
        connectIntegration: {
          ...state.connectIntegration,
          status: "failed",
          error: action.error,
        }
      };

    case "SET_INTEGRATION_CONNECT_STATUS":
      return {
        ...state,
        connectIntegration: {
          ...state.connectIntegration,
          status: action.status,
        }
      };

    /* Brivo integration */
    case "GET_BRIVO_SITES_START":
      return {
        ...state,
      };

    case "GET_BRIVO_SITES_FAILED":
      return {
        ...state,
      };

    case "GET_BRIVO_SITES_SUCCESS":
      return {
        ...state,
        brivo: {
          ...state.brivo,
          sites: action.data,
        }
      };

    case "GET_BRIVO_SITES_CANCEL":
      return {
        ...state,
      };

    case "GET_BRIVO_LINKED_SITES_START":
      return {
        ...state,
      };

    case "GET_BRIVO_LINKED_SITES_FAILED":
      return {
        ...state,
      };

    case "GET_BRIVO_LINKED_SITES_SUCCESS":
      return {
        ...state,
        brivo: {
          ...state.brivo,
          linkedSites: action.data,
        }
      };

    case "GET_BRIVO_LINKED_SITES_CANCEL":
      return {
        ...state,
      };

    /* Uptick integration */
    case "GET_UPTICK_SITES_START":
      return {
        ...state,
        uptick: {
          ...state.uptick,
          loading: true,
        }
      };

    case "GET_UPTICK_SITES_FAILED":
      return {
        ...state,
      };

    case "GET_UPTICK_SITES_SUCCESS":
      return {
        ...state,
        uptick: {
          ...state.uptick,
          sites: action?.data?.data,
          pagination: {
            ...action?.data?.pagination,
          },
          loading: false,
        }
      };

    case "GET_UPTICK_SITES_CANCEL":
      return {
        ...state,
      };

    case "GET_UPTICK_LINKED_SITES_START":
      return {
        ...state,
      };

    case "GET_UPTICK_LINKED_SITES_FAILED":
      return {
        ...state,
      };

    case "GET_UPTICK_LINKED_SITES_SUCCESS":
      return {
        ...state,
        uptick: {
          ...state.uptick,
          linkedSites: action?.data,
        }
      };

    case "GET_UPTICK_LINKED_SITES_CANCEL":
      return {
        ...state,
      };

    /* Priority Events Management */
    case "GET_EVENT_PAIR_LIST_START":
      return {
        ...state,
      };

    case "GET_EVENT_PAIR_LIST_FAILED":
      return {
        ...state,
      };

    case "GET_EVENT_PAIR_LIST_SUCCESS":
      return {
        ...state,
        priorityEventsManagement: {
          ...state.priorityEventsManagement,
          eventPairList: action.data,
        }
      };

    case "GET_EVENT_PAIR_LIST_CANCEL":
      return {
        ...state,
      };

    case "GET_EVENT_PAIR_MAPS_START":
      return {
        ...state,
      };

    case "GET_EVENT_PAIR_MAPS_FAILED":
      return {
        ...state,
      };

    case "GET_EVENT_PAIR_MAPS_SUCCESS":
      return {
        ...state,
        priorityEventsManagement: {
          ...state.priorityEventsManagement,
          eventPairMaps: {
            default_event_pair_maps: action.data.default_event_pair_maps,
            enterprise_event_pair_maps: action.data.enterprise_event_pair_maps,
          },
          stepTypeList: action.data.sop_action_library,
        },
      }

    case "GET_EVENT_PAIR_MAPS_CANCEL":
      return {
        ...state,
      };

    case "SET_CURRENT_EVENT_PAIR_MAP":
      return {
        ...state,
        priorityEventsManagement: {
          ...state.priorityEventsManagement,
          currentEventPairMap: action.currentEventPairMap,
        }
      }
      

      /* Pin Maps */
      case "LOAD_PIN_MAPS_START":
        return {
          ...state,
          loadingPinMaps: true,
        };
  
      case "LOAD_PIN_MAPS_SUCCESS":
        return {
          ...state,
          pinMaps: action.data,
          loadingPinMaps: false,
        }
  
      case "LOAD_PIN_MAPS_FAILED":
        return {
          ...state,
          loadingPinMaps: false,
        };
  
      case "LOAD_PIN_MAPS_CANCEL":
        return {
          ...state,
          loadingPinMaps: false,
        };

    /* Add Group */
    case "REQUEST_ADD_GROUP_START":
      return {
        ...state,
        loading: true,
      };

    case "REQUEST_ADD_GROUP_SUCCESS":
      return {
        ...state,
        actions: {
          ...state.actions,
          addGroup: {
            ...state.actions.data,
            loading: false,
            success: true,
          },
        },
      };
    case "REQUEST_ADD_GROUP_FAILED":
      return {
        ...state,
        loadingGroupStructure: false,
      };
    default:
      return state;
  }
}
// recursion selector to rename deeply nested fields
function renameKey(obj, keysMap) {
  return _.transform(obj, function (result, value, key) {
    const currentKey = keysMap[key] || key;
    result[currentKey] = _.isObject(value) ? renameKey(value, keysMap) : value;
  });
}
